import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "shared/button";
import { Form, FormError, FormItem, FormRow } from "shared/form";
import { Loader } from "shared/loader";
import { validationSchema } from "shared/utils/validations.utils";
import axios from "axios";

const acceptedFiles = ["application/pdf"];

type TFiles = {
  cv: {
    file: File | null;
    error: string | null;
  };
  pres: {
    file: File | null;
    error: string | null;
  };
};

type TState = {
  loading: boolean;
  success: boolean;
  error: boolean;
};

const defaultFiles = {
  cv: {
    file: null,
    error: null,
  },
  pres: {
    file: null,
    error: null,
  },
};

export const Collabora = () => {
  const showForm: boolean = false;

  const [state, seState] = useState<TState>({
    loading: false,
    success: false,
    error: false,
  });

  const [files, setFiles] = useState<TFiles>(defaultFiles);

  const defaultForm = {
    nome: null,
    cognome: null,
    telefono: null,
    email: null,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", defaultValues: defaultForm });

  const isFileValid = (target: any, file: File) => {
    if (file.size > 3000000) {
      setFiles({
        ...files,
        [target]: {
          file: null,
          error: "Dimensione non valida, massimo 3mb",
        },
      });
      return false;
    }
    if (!acceptedFiles.includes(file.type)) {
      setFiles({
        ...files,
        [target]: {
          file: null,
          error: "Tipologia non valida, seleziona PDF",
        },
      });
      return false;
    }

    return true;
  };

  const handleCv = (target: any, e: any) => {
    const file = e.target.files[0];

    if (isFileValid(target, file)) {
      setFiles({
        ...files,
        [target]: {
          file,
          error: null,
        },
      });
    }
  };

  const onRemoveFile = (target: any) => {
    setFiles({
      ...files,
      [target]: {
        file: null,
        error: null,
      },
    });
  };

  const onError = () => {
    seState({
      ...state,
      loading: false,
      error: true,
    });
  };

  const onSuccess = () => {
    seState({
      ...state,
      loading: false,
      success: true,
    });
    clearForm();
  };

  const clearForm = () => {
    reset();
    setFiles(defaultFiles);
  };

  const onSubmit = async (data: any) => {
    seState({
      ...state,
      loading: true,
    });

    const fd = new FormData();
    fd.append("cv", files.cv.file as any);
    fd.append("pres", files.pres.file as any);
    fd.append("data", JSON.stringify(data));

    try {
      const res = await axios.post(
        "http://localhost/magog/public/mail/mail.php",
        fd
      );
      if (res.data.sent) {
        onSuccess();
      } else {
        onError();
      }
    } catch (e: any) {
      onError();
    }
  };

  return (
    <div className="container full m-auto bg-black color-white">
      <div className="collabora  ">
        <div className="collabora-intro">
          Il Gruppo Editoriale MAGOG è un incubatore di progetti editoriali e
          culturali nonché un centro di formazione permanente per tirocinanti e
          professionisti. Quest'area è riservata a tutti coloro che intendono
          presentare una candidatura spontanea. <br />
          <br />
          Invia una email a <i>info@gruppomagog.it</i> indicando nome, cognome e
          numero di telefono e allegando curriculum vitae e una lettera di
          presentazione in cui ci dici chi sei, le motivazioni della candidatura
          e perché e in che termini vorresti far parte di questo gruppo
          editoriale.
        </div>

        {showForm && (
          <div className="collabora-form">
            <Form>
              <FormRow>
                <FormItem htmlFor="nome" label="Nome">
                  <input
                    className={`form-control ${
                      errors.nome ? "is-invalid" : ""
                    }`}
                    id="nome"
                    placeholder="Nome"
                    {...register("nome", validationSchema.required)}
                  />
                  <FormError
                    error={errors.nome}
                    validation={validationSchema.required}
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem htmlFor="cognome" label="Cognome">
                  <input
                    className={`form-control ${
                      errors.cognome ? "is-invalid" : ""
                    }`}
                    id="cognome"
                    placeholder="Cognome"
                    {...register("cognome", validationSchema.required)}
                  />
                  <FormError
                    error={errors.cognome}
                    validation={validationSchema.required}
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem htmlFor="telefono" label="Telefono">
                  <input
                    className={`form-control ${
                      errors.telefono ? "is-invalid" : ""
                    }`}
                    id="telefono"
                    placeholder="Telefono"
                    {...register("telefono", validationSchema.required)}
                  />
                  <FormError
                    error={errors.telefono}
                    validation={validationSchema.required}
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem htmlFor="email" label="Email">
                  <input
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    id="email"
                    placeholder="Email"
                    {...register("email", validationSchema.email)}
                  />
                  <FormError
                    error={errors.email}
                    validation={validationSchema.email}
                  />
                </FormItem>
              </FormRow>

              <FormRow>
                <FormItem label="CV">
                  {!files.cv.file && (
                    <label htmlFor="cv" className="file-input">
                      Seleziona
                    </label>
                  )}
                  {files.cv.file && (
                    <div className="file-preview">
                      <span>{files.cv.file.name}</span>
                      <div
                        className="file-remove"
                        onClick={() => onRemoveFile("cv")}
                      >
                        X
                      </div>
                    </div>
                  )}
                  <input
                    type="file"
                    id="cv"
                    onChange={e => handleCv("cv", e)}
                    style={{ display: "none" }}
                  />
                  {files.cv.error && (
                    <div className="form-error">{files.cv.error}</div>
                  )}
                </FormItem>
              </FormRow>

              <FormRow>
                <FormItem label="Lettera di presentazione">
                  {!files.pres.file && (
                    <label htmlFor="pres" className="file-input">
                      Seleziona
                    </label>
                  )}
                  {files.pres.file && (
                    <div className="file-preview">
                      <span>{files.pres.file.name}</span>
                      <div
                        className="file-remove"
                        onClick={() => onRemoveFile("pres")}
                      >
                        X
                      </div>
                    </div>
                  )}
                  <input
                    type="file"
                    id="pres"
                    onChange={e => handleCv("pres", e)}
                    style={{ display: "none" }}
                  />
                  {files.pres.error && (
                    <div className="form-error">{files.pres.error}</div>
                  )}
                </FormItem>
              </FormRow>

              <FormRow>
                <FormItem>
                  <Button
                    className="full-width"
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      !isValid /* || !files.cv.file || !files.pres.file */
                    }
                  >
                    {!state.loading ? (
                      "Invia richiesta"
                    ) : (
                      <Loader size="sm" color="black" />
                    )}
                  </Button>
                </FormItem>
              </FormRow>

              {state.success && (
                <div className="response">Richiesta inviata</div>
              )}
              {state.error && (
                <div className="response">Errore durante l'invio</div>
              )}
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};
